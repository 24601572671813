import Vue from 'vue'
import VueRouter from 'vue-router'
import ViewUI from 'view-design';
Vue.use(ViewUI);

//解决路由重复点击
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// const no = () =>
//     import ('../views/404/404.vue')

const Login = () =>
  import('../views/login/index.vue')
const register = () =>
  import('../views/register/index.vue')

const password = () =>
  import('@/views/password/index.vue')

const display = () =>
  import('../views/display.vue') //展示

// const Home = () =>
//     import ('../views/home/index.vue')

const homeTopic = () =>
  import('../views/homeTopic/index.vue')

const homeSearch = () =>
  import('../views/homeSearch/index.vue')

const homeDetails = () =>
  import('../views/homeDetails/index.vue')

const liveStream = () =>
  import('../views/liveStream/index.vue')

const postCase = () =>
  import('../views/postCase/index.vue')

const my = () =>
  import('../views/my/index.vue')

const certification = () =>
  import('../views/certification/index.vue')

const certificationf = () =>
  import('../views/certificationf/index.vue')


const Information = () =>
  import('../views/Information/index.vue')

const InformationDetails = () =>
  import('../views/InformationDetails/index.vue')

const questionnaire = () =>
  import('@/views/questionnaire/index.vue')

const questionnaireDel = () =>
  import('@/views/questionnaire/questionnaireDel.vue')


const routes = [{
  path: "/",
  redirect: "/homeTopic",
},
{
  path: '/display',
  name: 'display',
  component: display,
  children: [
    // {
    //     path: '/home',
    //     name: 'home',
    //     component: Home,
    // },
    {
      path: '/homeTopic',
      name: 'homeTopic',
      component: homeTopic,
    },
    {
      path: '/homeSearch',
      name: 'homeSearch',
      component: homeSearch,
    },
    {
      path: '/homeDetails',
      name: 'homeDetails',
      component: homeDetails,
    },
    {
      path: '/Information',
      name: 'Information',
      component: Information,
    },
    {
      path: '/InformationDetails',
      name: 'InformationDetails',
      component: InformationDetails,
    },
    {
      path: '/liveStream',
      name: 'liveStream',
      component: liveStream,
    },
    {
      path: '/postCase',
      name: 'postCase',
      component: postCase,
    },
    {
      path: '/my',
      name: 'my',
      component: my,
    },
    {
      path: '/questionnaire',
      name: 'questionnaire',
      component: questionnaire,
    },
    {
      path: '/questionnaireDel',
      name: 'questionnaireDel',
      component: questionnaireDel,
    },

  ]
},
//404页面
// {
//     path: '/:catchAll(.*)',
//     name: '404',
//     component: no 
// },
{
  path: '/login',
  name: 'login',
  component: Login
},
{
  path: '/register',
  name: 'register',
  component: register
},
{
  path: '/password',
  name: 'password',
  component: password
},
//认证 
{
  path: '/certification',
  name: 'certification',
  component: certification,
},
{
  path: '/certificationf',
  name: 'certificationf',
  component: certificationf,
},
]


Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

//* 配置加载进度条样式
ViewUI.LoadingBar.config({
  color: '#0147a1',
  height: 3
});

// 路由 
router.beforeEach((to, form, next) => {
  ViewUI.LoadingBar.finish(); //加载进度条   
  if (to.path == '/my' || to.path == '/questionnaire') {
    if (!localStorage.getItem('token')) {
      router.push('/login');
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router